html {
  background-color: #E0D3D3;
  cursor: crosshair;
}

canvas {
  margin: 0;
  padding: 0;
  display: block;
}

body {
  margin: 0;
  padding: 0;
}

a {
  font-family: "Space Mono", monospace;
  font-weight: 700;
  text-decoration: none;
  color: #000000;
}

a:hover {
  cursor: pointer;
  font-weight: 400;
  background: #000000;
  color: #E0D3D3
}